<template>
  <div>
    <vue-final-modal v-model="isModalOpen" @keydown.esc="ModalClose" tabindex="0">
      <Modal_Segment_Audience_profile :profileInfo="$store.state.Audience.isProfileData" :isActiveProfileInfo="isActiveProfileInfo" @ModalClose="ModalClose" />
    </vue-final-modal>
    <WrapperAllProfiles @Filter-wrapper="onFilterWrapper" />
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <div class="w-full flex justify-center items-center">
        <Spinner class="mt-10" color="blue" v-if="!$store.state.Audience.isProjectAudienceActive" />
      </div>
      <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col" v-if="$store.state.Audience.isProjectAudienceActive">
        <ul class="w-full mb-20" v-if="$store.state.Audience.projectAudience.length">
          <div class="flex flex-col items-center mb-2 w-full">
            <PaginationSelect :current_page="$store.state.Audience.projectAudienceMeta.current_page" :from="$store.state.Audience.projectAudienceMeta.from" :last_page="$store.state.Audience.projectAudienceMeta.last_page" :path="$store.state.Audience.projectAudienceMeta.path" :per_page="$store.state.Audience.projectAudienceMeta.per_page" :to="$store.state.Audience.projectAudienceMeta.to" :total="$store.state.Audience.projectAudienceMeta.total" :dropdown="false" @change-page="paginationChange" />
          </div>
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr class="">
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                        <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'lastname' ? '-lastname' : 'lastname')">
                          <span>{{ t("audience.lastName", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-lastname'" />
                          <TableSortAsc v-if="sort == 'lastname'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'firstname' ? '-firstname' : 'firstname')">
                          <span>{{ t("audience.name", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-firstname'" />
                          <TableSortAsc v-if="sort == 'firstname'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'email' ? '-email' : 'email')">
                          <span>{{ t("audience.email", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-email'" />
                          <TableSortAsc v-if="sort == 'email'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'sales' ? '-sales' : 'sales')">
                          <span>{{ t("audience.purchases", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-sales'" />
                          <TableSortAsc v-if="sort == 'sales'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-widerr">
                        <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'items_total' ? '-items_total' : 'items_total')">
                          <span>{{ t("audience.product", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-items_total'" />
                          <TableSortAsc v-if="sort == 'items_total'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'items_avg' ? '-items_avg' : 'items_avg')">
                          <span>{{ t("audience.average", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-items_avg'" />
                          <TableSortAsc v-if="sort == 'items_avg'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'revenue_total' ? '-revenue_total' : 'revenue_total')">
                          <span>{{ t("audience.revenue", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-revenue_total'" />
                          <TableSortAsc v-if="sort == 'revenue_total'" />
                        </div>
                      </th>
                      <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'buyer_type' ? '-buyer_type' : 'buyer_type')">
                          <span>{{ t("audience.type", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          <TableSortDesc v-if="sort == '-buyer_type'" />
                          <TableSortAsc v-if="sort == 'buyer_type'" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in $store.state.Audience.projectAudience" :key="index" class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-pointer border border-gray-100" @click="showProfileInfo(user.email)">
                      <td class="px-4 py-3 whitespace-nowrap flex flex-row gap-4 items-center">
                        <div class="text-sm text-gray-700 font-semibold">{{ user.lastname }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <div class="text-sm text-gray-700 font-semibold">{{ user.firstname }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <div class="text-sm text-gray-700 font-semibold">{{ user.email }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <div class="text-sm text-gray-700 font-normal">{{ $filters.number(user.sales ? user.sales : 0) }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <!-- <div>Ingresos</div> -->
                        <div class="text-sm text-gray-700 font-normal">{{ $filters.number(user.items_total ? user.items_total : 0) }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <!-- <div>Transacciones</div> -->
                        <div class="text-sm text-gray-700 font-normal">{{ $filters.currency(user.revenue_avg ? user.revenue_avg : 0) }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <!-- <div>Ticket Promedio</div> -->
                        <div class="text-sm text-gray-700 font-normal">{{ $filters.currency(user.revenue_total ? user.revenue_total : 0) }}</div>
                      </td>
                      <td class="px-4 py-3 whitespace-nowrap">
                        <!-- <div>Ticket Promedio</div> -->
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="user.buyer_type == 'offline'">{{ user.buyer_type }}</span>
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase" v-if="user.buyer_type == 'online'">{{ user.buyer_type }}</span>
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 uppercase" v-if="user.buyer_type == 'omnichannel'">{{ user.buyer_type }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center mt-2 w-full">
            <PaginationSelect :current_page="$store.state.Audience.projectAudienceMeta.current_page" :from="$store.state.Audience.projectAudienceMeta.from" :last_page="$store.state.Audience.projectAudienceMeta.last_page" :path="$store.state.Audience.projectAudienceMeta.path" :per_page="$store.state.Audience.projectAudienceMeta.per_page" :to="$store.state.Audience.projectAudienceMeta.to" :total="$store.state.Audience.projectAudienceMeta.total" :dropdown="false" @change-page="paginationChange" />
          </div>
        </ul>
        <div class="text-center block mx-auto my-20" v-if="$store.state.Audience.projectAudience.length === 0">
          <NoResultsFound>
            <div>
              <p class="text-gray-600 font-semibold text-2xl uppercase">{{ t("audience.noFound", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
              <!-- <span class="text-md text-gray-400 font-semibold"  v-if="!hasFilter">You can create a segment to target a specific part of your audience</span > -->
              <span class="text-md text-gray-400" v-if="hasFilter">{{ t("audience.filter", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
            </div>
            <!-- <template v-slot:buttons v-if="!hasFilter">
                            <button @click="createSegentModal" class="btn btn-xs btn-primary">create my first segment</button>
                          </template> -->
          </NoResultsFound>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
import Spinner from "@/components/UI/Spinner"
import WrapperAllProfiles from "@/components/Profiles/WrapperAllProfiles"
import PaginationSelect from "@/components/PaginationSelect"
import Modal_Segment_Audience_profile from "@/components/Profiles/Modal_Segment_Audience_Profiles"
import NoContent from "@/components/Utils/NoContent"
import TableSortDesc from "@/components/Table/TableSortDesc"
import TableSortAsc from "@/components/Table/TableSortAsc"
import NoResultsFound from "@/components/Utils/NoResultsFound"

export default {
  name: "All Profiles",
  components: {
    WrapperAllProfiles,
    TransitionFade,
    Spinner,
    PaginationSelect,
    Modal_Segment_Audience_profile,
    NoContent,
    TableSortDesc,
    TableSortAsc,
    NoResultsFound
  },
  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      loading: true,
      filterKeyword: "",
      sort: "-id",
      default: {
        sort: "-id",
        limit: 50
      },
      isModalOpen: false,
      isActiveProfileInfo: false,
      profileInfo: null,
      hasFilter: false
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {},
  async created() {
    this.currentProject ? this.getAllProfilesInfo() : null
  },
  methods: {
    sortAndRefresh(sort) {
      this.sort = sort
      this.getAllProfilesInfo()
    },

    onFilterWrapper: function (value) {
      if (value == "") this.hasFilter = false
      else if (value) this.hasFilter = true
      this.filterKeyword = value
      this.currentProject ? this.getAllProfilesInfo() : null
    },

    getAllProfilesInfo: async function (config = {}) {
      let currentProject = this.currentProject
      let filter = {}
      if (this.filterKeyword) filter["email"] = `${this.filterKeyword}`
      filter.page = config.page || 1
      filter.sort = this.sort || this.default.sort
      filter.limit = config.limit || this.default.limit
      this.$store.dispatch("Audience/getAudience", { currentProject, filter })
    },

    paginationChange(page) {
      this.getAllProfilesInfo({ page })
    },

    showProfileInfo(value) {
      let currentProject = this.currentProject
      this.isActiveProfileInfo = false
      this.profileInfo = []
      this.ModalOpen()
      this.$store.dispatch("Audience/showSegmentProfileWithEmail", { currentProject, value }).then((res) => {
        if (res.status == 404) this.$toast.error("Error, please try again in a few minutes"), this.ModalClose()
        if (res.status == 422) (this.isActiveProfileInfo = false), this.$toast.error("Error, this information cannot be displayed"), this.ModalClose()
        if (res.status == 200) this.isActiveProfileInfo = true
      })
    },

    ModalOpen() {
      this.isModalOpen = true
    },

    ModalClose() {
      this.isModalOpen = false
    }
  }
}
</script>
