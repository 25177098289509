<template>
  <div>
    <div class="border-t bg-white">
      <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <!--Left Filter-->
        <div class="flex flex-row items-center justify-between md:justify-start">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
            <span
              >{{ $t("audience.audience") }} <span class="font-light">{{ $t("audience.allProfiles") }}</span>
            </span>
          </h4>
        </div>
        <div>
          <Button type="button" @click="toAudience" :label="$t('audience.audience')" class="p-button-success" />
        </div>
      </div>
      <!--SubNav Filter-->
      <div class="border-t bg-white border-b border-gray-200">
        <div class="container-xl w-full px-2 mx-auto h-14 flex flex-row lg:flex-row md:items-center lg:items-center justify-between">
          <!--Search-->
          <div class="h-full flex flex-row items-center w-full">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-400 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input type="text" placeholder="Search..." class="h-full w-full px-2 focus:outline-none focus:border-none" v-model="filterKeyword" v-debounce:700ms="search" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"

export default {
  name: "WrapperSegmentProfiles",
  components: {
    Button,
  },
  emits: ["Filter-wrapper"],
  data: function () {
    return {
      show: false,
      filterKeyword: ""
    }
  },
  mounted() {},
  methods: {
    search: function () {
      this.$emit("Filter-wrapper", this.filterKeyword)
    },
    toAudience() {
      this.$router.push({
        name: "Audience",
        params: { id: this.$store.state.Projects.idRoute }
      })
    }
  }
}
</script>

<style scoped>
.p-inputtext {
  border: none;
  box-shadow: none;
}
</style>
